<template>
  <div>
    <div class="mt-4 grid grid-cols-1 gap-y-6 lg:grid-cols-2 lg:gap-x-8">
      <div>
        <label @click="hasMembership = true"
          class="my-4 relative flex cursor-pointer rounded-lg border bg-white px-4 py-8 shadow-sm focus:outline-none"
          :class="hasMembership ? choiceActive : ''">
          <input type="radio" name="project-type" value="Newsletter" class="sr-only"
            aria-labelledby="project-type-0-label"
            aria-describedby="project-type-0-description-0 project-type-0-description-1">
          <span class="flex flex-1">
            <span class="flex flex-col">
              <span id="project-type-0-label" class="block text-sm font-medium text-gray-900">I have a paid TBS
                Membership</span>
            </span>
          </span>
          <svg v-if="hasMembership" class="h-5 w-5 text-red-600" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd" />
          </svg>
          <span class="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"
            :class="hasMembership ? choiceActiveSpan : ''"></span>
        </label>

        <label @click="hasMembership = false"
          class="relative flex cursor-pointer rounded-lg border bg-white px-4 py-8 shadow-sm focus:outline-none"
          :class="!hasMembership ? choiceActive : ''">
          <input type="radio" name="project-type" value="Existing Customers" class="sr-only"
            aria-labelledby="project-type-1-label"
            aria-describedby="project-type-1-description-0 project-type-1-description-1">
          <span class="flex flex-1">
            <span class="flex flex-col">
              <span id="project-type-1-label" class="block text-sm font-medium text-gray-900">I do not have a paid TBS
                Membership</span>
            </span>
          </span>
          <svg v-if="!hasMembership" class="h-5 w-5 text-red-600" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd" />
          </svg>
          <span class="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"
            :class="!hasMembership ? choiceActiveSpan : ''"></span>
        </label>
      </div>
      <div class="my-4">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div v-if="hasMembership">
              <h3 class="mt-2 mb-4 font-bold ">Use your The Broker Site credentials below</h3>
              <div class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-700">Email address </label>
                  <div class="mt-1">
                    <input v-model="loginPayload.email" id="email" name="email" type="email" autocomplete="email" required
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm">
                  </div>
                </div>

                <div>
                  <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                  <div class="mt-1">
                    <input v-model="loginPayload.password" id="password" name="password" type="password"
                      autocomplete="current-password" required
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm">
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div class="text-sm">
                    <a href="https://www.thebrokersite.com" target="_blank"
                      class="font-medium text-indigo-600 hover:text-indigo-500">Forgot your The Broker Site
                      password?</a>
                  </div>
                </div>

                <div>
                  <button @click="nextWithLogin" type="submit"
                    class="flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Sign-in
                    and continue</button>
                </div>
              </div>
            </div>
            <div v-else>
              <h3 class="mt-2 mb-4 font-bold">The European Broker Meeting is for paid TBS members only.</h3>
              <div class="text-gray-600 text-sm mb-6">
                After registration and verification you will be contacted by one of our representatives for 1 year TBS membership (€ 675) and your EBM Attendance.
                Your account on TBS and EBM will be activated immediately after your account has been verified and paid.
              </div>
              <div>
                <button @click="nextWithoutLogin" type="button"
                  class="flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'
import eventBus from "../utils/eventBus";
import { mapState } from 'vuex';

export default {
  name: "RegisterStep1",
  data() {
    return {
      hasMembership: true,
      choiceActive: "border-red-500 ring-2 ring-red-500",
      choiceActiveSpan: "border border-red-500",
      loginPayload: { email: null, password: null },
    };
  },
  computed: {
    ...mapState(['countriesAll']),
  },
  methods: {
    async nextWithLogin() {

      const resp = await this.$http.post(`/api/register/loginTBSToGrabInfo`, this.loginPayload)
      if (resp.status !== 200) {
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Fetching records from European Broker Meeting failed.",
          body: resp.data && resp.data.payload ? resp.data.payload.msg : resp.status,
          timeoutInMS: 5000,
        });
        return
      }
      const { companyProfile, attendees } = resp.data.result


      // From Country name -> Country ID
      // add tbsCompanyId: true
      // add categories: []
      const countriesByName = _.keyBy(this.countriesAll, 'country')
      _.extend(companyProfile, {
        country: countriesByName[companyProfile.country] ? countriesByName[companyProfile.country].country_id : null,
        categories: [],
      })

      this.$parent.$parent.toStep({ step: 2, companyProfile, attendees })
    },
    async nextWithoutLogin() {
      const companyProfile = this.$parent.$parent.emptyCompany()
      this.$parent.$parent.toStep({ step: 2, companyProfile })
    },

  },
};
</script>
