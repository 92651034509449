import _ from "lodash"
import { mapState, mapActions, mapGetters } from "vuex"
import Password from "vue-password-strength-meter"

import eventBus from "../utils/eventBus"

export default {
  data() {
    return {
      doShowSigninModal: false,
      doShowPasswordResetModal: false,
      passwordResetEmail: "",
      resetToken: null,
      loginPayload: {
        email: null,
        password: null,
      },

      // password reset
      showContactEditDropdown: false, // only for one of 2 views.
      showChangePasswordModal: false,
      resetPasswordInput: null,
      passwordFeedback: {},
      passwordScore: null,
    }
  },
  components: {
    Password,
  },
  computed: {
    ...mapState([]),
    passwordSuggestion() {
      if (!this.passwordFeedback.suggestions || !this.passwordFeedback.suggestions.length) return ""
      return this.passwordFeedback.suggestions[0]
    },
    passwordSubmitDisabled() {
      return this.passwordScore < 2
    },
  },
  methods: {
    ...mapActions(["signin", "signout", "resetPassword", "requestPassword", "verifyResetToken", "resetPassword"]),
    showSignInModal() {
      this.loginPayload = {
        email: null,
        password: null,
      }
      this.doShowSigninModal = true
      this.doShowPasswordResetModal = false
    },
    showPasswordResetModal() {
      this.doShowSigninModal = false
      this.doShowPasswordResetModal = true
    },
    signoutWrap() {
      this.signout()
      // this.$router.go(this.$router.currentRoute)
      this.$router.push('/') // updated to force going to the homepage after signing out. This prevents member only pages form showing empty when signed out
    },
    submitSignin() {
      this.signin(this.loginPayload)
        .then(() => {
          const route = this.$route

          if (this.$route.query.reset) {
            this.$router.replace({ query: {} })
          }
          this.$router.go(route)
          // this.$router.push('/scheduler') // when signed in, the user gets directed straight to the scheduler, switch off during testing!
        })
        .catch(err => {
          if (err.status === 400) {
            eventBus.$emit("createSnackbar", {
              type: "error",
              title: "Could not login",
              body: "Your email address and/or password is incorrect. <br/> Please try again or click 'Forgot your Password?' to reset it.",
              timeoutInMS: 5000,
            })
          } else {
            eventBus.$emit("createSnackbar", {
              type: "error",
              title: "Something went wrong",
              body: "Could not login. Please try again. <br/createSnackbar> If the problem persists please contact European Broker Meeting",
              timeoutInMS: 5000,
            })
          }
        })
    },
    // subig reqeust to get mail to be able to reset password
    submitPasswordReset() {
      this.requestPassword({ email: this.passwordResetEmail })
        .then(resp => {
          if (resp.data.status !== 200) throw resp
          this.doShowPasswordResetModal = false

          eventBus.$emit("createSnackbar", {
            type: "success",
            title: "Password request successfull.",
            body: "If we have your email-address on file, you will receive an email with instructions soon.",
            timeoutInMS: 10000,
          })
        })
        .catch(err => {
          eventBus.$emit("createSnackbar", {
            type: "error",
            title: "Something went wrong",
            body: "Requesting a password reset failed. Please try again. <br/> If the problem persists please contact European Broker Meeting",
            timeoutInMS: 5000,
          })
        })
    },

    async submitChangePassword(token = null) {
      const resp = await this.resetPassword({
        password: this.resetPasswordInput,
        token,
      })

      if (resp.data.status !== 200) {
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Something went wrong",
          body: "Password could not be updated. Please try again. <br/> If the problem persists please contact European Broker Meeting",
          timeoutInMS: 5000,
        })
      } else {
        eventBus.$emit("createSnackbar", {
          type: "success",
          title: token ? "Password succesfully updated. Please login with your newly created password" : "Password succesfully updated",
        })

        if (token) {
          // remove ?reset=xxx from url
          this.$router.replace({ query: {} })
        }

        this.showChangePasswordModal = false
      }
    },
    doShowChangePasswordModal() {
      this.resetPasswordInput = null
      this.resetPasswordInputVerify = null
      this.showChangePasswordModal = true
      this.showContactEditDropdown = false
    },
    passwordFeedBack(feedback) {
      this.passwordFeedback = feedback
    },
    passwordScoreFeedback(score) {
      this.passwordScore = score
    },
  },
}
