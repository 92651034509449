<!-- eslint-disable no-debugger -->
<template>
  <div >
    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li v-for="(attendee,index) in attendeesLocal" :key="index" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div class="relative flex w-full items-center justify-between space-x-6 p-6">
          <div class="flex-1 truncate">
            <div class="flex items-center space-x-3 mt-2 mb-3">
              <h3 class="truncate text-lg font-medium text-gray-900">{{attendee.name}}</h3>
              <span class="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">{{attendee.function}}</span>
            </div>
            <p class="my-2 truncate text-sm">
              <svg class="h-5 w-5 float-left text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
              </svg>
              <span class="ml-2 text-gray-800">{{attendee.email}}</span>
            </p>
            <p class="my-2 truncate text-sm">
              <svg class="h-5 w-5 float-left  text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clip-rule="evenodd" />
              </svg>
              <span class="ml-2 text-gray-800">{{attendee.tel}}</span>
            </p>
          </div>

          <div v-if='attendeesLocal.length > 1' @click='removeAttendee(index)' class="absolute top-3.5 right-12 cursor-pointer">
            <svg class="h-6 w-6 text-gray-400 hover:text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </div>

          <div @click='openModalForEdit(attendee, index)' class="absolute top-3.5 right-4 cursor-pointer">
            <svg class="h-6 w-6 text-gray-400 hover:text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"></path>
            </svg>
          </div>
        </div>
      </li>
      <li @click='openModalForAdd' class="cursor-pointer col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div class="relative flex w-full items-center justify-between space-x-6 p-6 ">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-gray-600">
            <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
          </svg>
          New Attendee
        </div>
      </li>
    </ul>
    <div class="pt-5">
      <div class="flex justify-end">
        <button @click='prev' type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Back to Preferences</button>
        <button @click='next' :disabled='!isValid' type="button" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">{{nextButtonText}}</button>
      </div>
    </div>

    <modal v-if="showModal" :header="modalHeader" :affirmative-button-label="affirmModalButton"  @submit="saveModal" @cancel="cancelModal" :disableSubmit="!checkAttendeeOk" >
      <template #body>
        <div class="text-gray-800 mx-2">
          <div>
            <div class="relative mt-1 rounded-md shadow-sm">
              <input id="attendee-name" v-model='attendeeToEdit.name' type="text" name="attendee-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Attendee name">
            </div>
            <!-- <p class="mt-2 text-sm text-red-600" id="attendee-name-error">Your password must be less than 4 characters.</p> -->
          </div>
          <div>
            <div class="relative mt-1 rounded-md shadow-sm">
              <input id="attendee-function" v-model='attendeeToEdit.function' type="text" name="attendee-function" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Function title">
            </div>
            <!-- <p class="mt-2 text-sm text-red-600" id="attendee-function-error">Your password must be less than 4 characters.</p> -->
          </div>
          <div>
            <div class="relative mt-1 rounded-md shadow-sm">
              <input id="attendee-email" v-model='attendeeToEdit.email' type="email" name="attendee-email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Email address">
            </div>
            <!-- <p class="mt-2 text-sm text-red-600" id="attendee-email-error">Your password must be less than 4 characters.</p> -->
          </div>
          <div>
            <div class="relative mt-1 rounded-md shadow-sm">
              <input id="attendee-tel" v-model='attendeeToEdit.tel' type="text" name="attendee-tel" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Telephone number">
            </div>
            <!-- <p class="mt-2 text-sm text-red-600" id="attendee-tel-error">Your password must be less than 4 characters.</p> -->
          </div>

          <div @click="attendeeToEdit.tbd = !attendeeToEdit.tbd" class="mt-4">
            <button type="button" :class='{"bg-red-600": attendeeToEdit.tbd, "bg-gray-200": !attendeeToEdit.tbd}' class="float-left relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
              <span aria-hidden="true" :class='{"translate-x-5": attendeeToEdit.tbd, "translate-x-0": !attendeeToEdit.tbd }' class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
            </button>
            <span class="ml-3 inline-block mt-0.5">
              <span class="text-sm font-medium text-gray-900">I don't know the attendee details yet</span>
            </span>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex';
import Modal from "../components/modal.vue";

export default {
  name: "RegisterStep2",
  components: {
    Modal,
  },
  props: {
    attendees: Array,
  },
  data() {
    return {
      showModal: false,
      attendeesLocal: [],
      isNewAttendee: true,
      attendeeToEdit: null,
    };
  },
  computed: {

    isValid(){
      return this.attendeesLocal.length
    },
    nextButtonText(){
      // return this.isValid?  'Payment overview' : 'Please add at least one attendee'
      return this.isValid ? (this.$parent.$parent.companyProfile.tbsCompanyId === null ? 'Next' : 'Payment overview') : 'Please add at least one attendee';
    },
    modalHeader(){
      return this.isNewAttendee ? 'Add new attendee' : "Edit existing attendee"
    },
    affirmModalButton(){
      return this.isNewAttendee ? 'Save new attendee' : "Save existing attendee"
    },
    checkAttendeeOk(){
      if(!this.attendeeToEdit) return false
      if(this.attendeeToEdit.tbd) return true // check tbd -> all good
      if(!this.attendeeToEdit.name.trim()) return false 
      if(!this.attendeeToEdit.function.trim()) return false 
      if(!this.attendeeToEdit.email.trim()) return false 
      return true
    }
  },  
  created(){
    this.attendeesLocal = _.cloneDeep(this.attendees)
  },
  methods: {
    async next() {
      this.$parent.$parent.toStep({ step: 5, attendees: this.attendeesLocal })
    },
    async prev() {
      this.$parent.$parent.toStep({ step: 3, attendees: this.attendeesLocal })
    },
    removeAttendee(index){
      this.attendeesLocal.splice(index, 1)
    },
    openModalForEdit(attendee, index){
      this.attendeeToEdit = _.clone(attendee)
      this.attendeeToEdit.index = index
      this.isNewAttendee = false
      this.showModal = true
    },
    openModalForAdd(){
      this.attendeeToEdit = { name: '', function:'', email: '', tel: '', tbd: false }
      this.isNewAttendee = true
      this.showModal = true
    },
    saveModal(){

      // Defaults
      this.attendeeToEdit.name = this.attendeeToEdit.name || "TBD"
      this.attendeeToEdit.function = this.attendeeToEdit.function || "-"
      this.attendeeToEdit.email = this.attendeeToEdit.email || "-"
      this.attendeeToEdit.tel = this.attendeeToEdit.tel || "-"

      if(!this.isNewAttendee){
        const indexInArray = this.attendeeToEdit.index
        delete this.attendeeToEdit.index
        this.attendeesLocal.splice(indexInArray, 1, this.attendeeToEdit) // delete + add in correct location
      } else {
        this.attendeesLocal.push(this.attendeeToEdit)
      }
      this.showModal = false
    },
    cancelModal(){
      this.showModal = false
    },
  },
};
</script>
