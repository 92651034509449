<template>
  <base-layout :do-skip-header="false">
    <template #main>

      <div class="relative isolate pb-32 pt-24">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div v-if="cats.length">
            <div v-for="cat in cats" :key="cat.title" class="mb-24">
              <h2 class="text-lg font-semibold leading-8 tracking-tight text-red-600">{{ cat.name }}</h2>
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ cat.title }}</p>
              <div
                class="mx-auto mt-12 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900  sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
                <figure
                  class="col-span-2 hidden sm:block sm:rounded-2xl bg-gradient-to-br from-red-500 to-red-700 sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
                  <blockquote class="p-12 text-xl font-semibold leading-8 tracking-tight text-white/90">
                    <p>{{ `“${cat.featuredTestimonial.testimonial}”` }}</p>
                  </blockquote>
                  <figcaption class="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">
                    <div class="flex-auto">
                      <div class="font-semibold text-black">{{ cat.featuredTestimonial.author.name }}</div>
                      <div class="text-black/80">{{ `${cat.featuredTestimonial.author.company}` }}</div>
                    </div>
                  </figcaption>
                </figure>
                <div v-for="(columnGroup, columnGroupIdx) in cat.testimonials" :key="columnGroupIdx"
                  class="space-y-8 xl:contents xl:space-y-0">
                  <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx"
                    :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === cat.testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
                    <figure v-for="testimonial in column" :key="testimonial.author.company"
                      class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                      <blockquote class="text-gray-900">
                        <p>{{ `“${testimonial.testimonial}”` }}</p>
                      </blockquote>
                      <figcaption class="mt-6 flex items-center gap-x-4">
                        <div>
                          <div class="font-semibold">{{ testimonial.author.name }}</div>
                          <div class="text-gray-600">{{ `${testimonial.author.company}` }}</div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";

export default {
  name: "Register",
  components: {
    BaseLayout,
  },
  data() {
    return {
      cats: [],
    };
  },
  async mounted() {

    const { cats, testimonials } = await this.$http.get("/api/misc/testimonials").then(res => res.data.payload)

    // add empty data structures
    _.each(cats, cat => {
      cat.featuredTestimonial = null
      cat.testimonials = [[[], []], [[], []]]
      cat.testimonialLength = 0
    })

    _.each(testimonials, testimonial => {
      const cat = _.find(cats, { id: testimonial.catId })
      if (!cat) return // just skip if we find a testimonial with a catId that doesn't exist

      if (!cat.featuredTestimonial) {
        cat.featuredTestimonial = testimonial
      } else {
        const mod = (cat.testimonialLength - 1) % 4
        let arr
        switch (mod) {
          case 0:
            arr = cat.testimonials[0][0]
            break
          case 1:
            arr = cat.testimonials[0][1]
            break
          case 2:
            arr = cat.testimonials[1][0]
            break
          case 3:
            arr = cat.testimonials[1][1]
            break
        }
        arr.push(testimonial)
      }
      cat.testimonialLength++
    })

    this.cats = cats
  }
};
</script>
