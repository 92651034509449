<template>
  <ValidationObserver ref="form"  v-slot="{ invalid }">
    <div  class="space-y-8 divide-y divide-gray-200 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">Interests and preferences</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Please highlight your interests and trade preferences</p>
          </div>
          <div class="space-y-6 divide-y divide-gray-200 sm:space-y-5 sm:border-t sm:border-gray-200 sm:pt-5">
            <div class="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <ValidationProvider v-slot="{ errors }" rules="required|maxChoices" immediate class="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                  <div>
                    <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">Main interests</div>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Choose a maximum of 3</p>
                    <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
                  </div>
                  <div class="mt-4 sm:col-span-2 sm:mt-0">
                    <div class="max-w-lg space-y-4">
                      <div v-for="catOption in categoryOptionsEnriched" :key='catOption.vueKey' class="relative flex items-start">
                        <div class="flex h-5 items-center">
                          <input v-model='payload.categories' :id="catOption.vueKey" :value='catOption.id' type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500">
                        </div>
                        <div class="ml-3 text-sm">
                          <label :for="catOption.vueKey" class="font-medium text-gray-700">{{catOption.value}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-notifications">
                <ValidationProvider v-slot="{ errors }"  rules="required" immediate class="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                  <div>
                    <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">We trade mostly in</div>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Please highlight your interests and trade preferences</p>
                    <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="max-w-lg">
                      <div class="mt-4 space-y-4">
                        <div class="flex items-center">
                          <input id="used-equipment" v-model='payload.trading' name="trade-type" type="radio" value='used' class="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500">
                          <label for="used-equipment" class="ml-3 block text-sm font-medium text-gray-700">Used equipment</label>
                        </div>
                        <div class="flex items-center">
                          <input id="new-equipment" v-model='payload.trading' name="trade-type" type="radio" value='new' class="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500">
                          <label for="new-equipment" class="ml-3 block text-sm font-medium text-gray-700">New equipment</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <div class="flex justify-end">
        <button @click='prev' type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Back to Company profile</button>
        <button @click='next' type="button" :disabled="invalid" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Continue to attendees</button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex';

export default {
  name: "RegisterStep2",
  props: {
    companyProfile: Object,
  },
  data() {
    return {
      payload: null
    };
  },
  computed: {
    ...mapState(['categoryOptions']),
    categoryOptionsEnriched(){
      return _.map(this.categoryOptions, option => ({
        ...option,
        vueKey: `option-${option.id}`
      }))
    },
  },  
  created(){
    this.payload = _.cloneDeep(this.companyProfile)
  },
  isValid(){
    // return this.attendeesLocal.length
  },
  methods: {
    async next() {
      const isValid = await this.$refs.form.validate();
			if (!isValid) return;
      this.$parent.$parent.toStep({ step: 4, companyProfile: this.payload })
    },
    async prev() {
      this.$parent.$parent.toStep({ step: 2, companyProfile: this.payload })
    },
  },
};
</script>
