<template>
	<base-layout>
		<template v-slot:main>
			<div class="mt-4">
				<nav>
					<router-link v-if='!isEditMode' to="/attendees"
						class="flex items-center text-sm leading-5 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:underline transition duration-150 ease-in-out">
						<svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
							<path fill-rule="evenodd"
								d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
								clip-rule="evenodd" />
						</svg>
						Back to attendees
					</router-link>
				</nav>
				<div class="block sm:flex sm:justify-between sm:items-start">
					<div class="sm:float-left">
						<div class="mt-1 mb-2 md:flex md:items-center md:justify-between">
						<h1 class="flex-1 min-w-0 font-bold mt-2 leading-7 text-gray-900 text-2xl sm:text-3xl leading-9 sm:truncate tracking-tight">
							{{ selectedCompany.name }}
						</h1>
						</div>
						<div class="mt-1 hidden sm:flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
						<div v-if='selectedCompany.website && selectedCompany.website !== "-"' class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
							<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
							<path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
							<path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
							</svg>
							<a class='hover:underline' :href="selectedCompany.website" target="_blank" rel="noopener">{{ selectedCompany.website }}</a>
						</div>
						<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
							<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
							<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
							</svg>
							<span>{{ selectedCompany.country }}</span>
						</div>
						<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
							<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
							<path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
							</svg>
							<a class='hover:underline' :href="selectedCompanyEnriched.telLink">{{ selectedCompany.tel }}</a>
						</div>
						</div>
					</div>
					<div class="ml-auto bg-white border border-gray-300 shadow-md p-2 rounded cursor-pointer hover:border-gray-400 transition-border duration-300">
						<a :href="selectedCompany.website" target="_blank" rel="noopener"><img :src="'https://www.thebrokersite.com/images/logos/' + selectedCompany.username + '.jpg'" class="max-h-16 object-cover"></a>
					</div>
				</div>

				<div class="mt-6">
					<div class="grid grid-cols-12 gap-6">
						<div class="col-span-12 lg:col-span-6 bg-white shadow overflow-hidden sm:rounded-lg pb-4">
							<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
								<!-- <div class='float-right' v-if='selectedCompanyEnriched.picUrl' :style='selectedCompanyEnriched.picCSS'></div> -->
								<h3 class="text-lg leading-6 font-medium text-gray-900">Company Details</h3>

								<div class='float-right -mt-8'>
									<div v-if='selectedCompanyEnriched.isTrusted' class='member_trusted'
										title="This company is a 'Trusted Member'. Trusted members have been on www.EuropeanBrokerMeeting.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
									</div>
									<div v-else-if='selectedCompanyEnriched.isGold' class='member_gold'
										title="This company is a 'Gold Member'. Gold members have been on www.EuropeanBrokerMeeting.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
									</div>
									<div v-else-if='selectedCompanyEnriched.isDiamond' class='member_diamond'
										title="This company is a 'Diamond Member'. Diamond members have been on www.EuropeanBrokerMeeting.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
									</div>
								</div>
							</div>
							<div>
								<dl class='mt-2 mb-8 max-h-120 overflow-y-auto'>
									<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-sm leading-5 font-medium text-gray-500">Name</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{ selectedCompanyEnriched.name
										}}
										</dd>
									</div>

									<div v-if='user'>
										<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
											<dt class="text-sm leading-5 font-medium text-gray-500">Telephone</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">

												<a v-if='!isEditMode' class='text-red-700' :href="selectedCompanyEnriched.telLink">{{
													selectedCompanyEnriched.tel }}</a>
												<input v-else class='form-input block w-full' v-model="selectedCompanyEnriched.tel">
											</dd>
										</div>
										<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
											<dt class="text-sm leading-5 font-medium text-gray-500">Website</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
												<div v-if='!isEditMode'>
													<a v-if='selectedCompanyEnriched.website && selectedCompanyEnriched.website !== "-"'
														class='text-red-700' :href="selectedCompanyEnriched.website" target="_blank">{{
															selectedCompanyEnriched.website }}</a>
													<div v-else>-</div>
												</div>
												<input v-else class='form-input block w-full' v-model="selectedCompanyEnriched.website"
													@keydown.space="(event) => event.preventDefault()">
											</dd>
										</div>
										<div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
											<dt class="text-sm leading-5 font-medium text-gray-500">{{ vatLabel }}</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{
												selectedCompanyEnriched.vat }}
											</dd>
										</div>
										<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
											<dt class="text-sm leading-5 font-medium text-gray-500">Address</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
												<a v-if='!isEditMode && selectedCompanyEnriched.mapUrl' :href='selectedCompanyEnriched.mapUrl'
													target='_blank' class='text-red-700'>{{ selectedCompanyEnriched.address }}</a>
												<div v-else-if='!isEditMode && !selectedCompanyEnriched.mapUrl' target='_blank'>
													{{ selectedCompanyEnriched.address }}</div>
												<input v-else class='form-input block w-full' v-model="selectedCompanyEnriched.address">
											</dd>
										</div>
										<div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
											<dt class="text-sm leading-5 font-medium text-gray-500">Country</dt>
											<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{
												selectedCompanyEnriched.country
											}}
											</dd>
										</div>
									</div>

									<div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-sm leading-5 font-medium text-gray-500">
											Company Description<br>
											<span v-if="isEditMode" class="text-xs text-red-600"><i>{{ remainingCharacters }} characters remaining</i></span>
										</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
											<div v-if='!isEditMode' class="whitespace-pre-line">{{ selectedCompanyEnriched.profile }}</div>
											<textarea v-else class='form-input block w-full' v-model="selectedCompanyEnriched.profile"
												rows="6"></textarea>
										</dd>
									</div>
								</dl>

								<div v-if='isEditMode' class="px-4 sm:px-6">
									<!-- Main Interests -->
									<div class="sm:grid sm:grid-cols-2 sm:gap-8">
									<div>
										<div class="text-base font-semibold leading-6 text-gray-900">Main interests</div>
										<p class="mt-1 max-w-2xl text-sm text-gray-500">Choose a minimum of 1 and a maximum of 3</p>
										<p v-if="errors.length" class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
									</div>
									<div>
										<div class="mt-2 sm:mt-0 max-w-lg space-y-4">
										<div
											v-for="catOption in categoryOptionsEnriched"
											:key="catOption.vueKey"
											class="relative flex items-start"
										>
											<div class="flex h-5 items-center">
											<input
												:id="catOption.vueKey"
												:value="catOption.id"
												type="checkbox"
												class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
												v-model="selectedCategories"
												:disabled="isCheckboxDisabled(catOption.id)"
											/>
											</div>
											<div class="ml-3 text-sm">
											<label :for="catOption.vueKey" class="font-medium text-gray-700">{{ catOption.value }}</label>
											</div>
										</div>
										</div>
									</div>
									</div>

									<!-- Trade Preferences -->
									<div class="sm:grid sm:grid-cols-2 sm:gap-8 mt-10">
									<div>
										<div class="text-base font-semibold text-gray-900 sm:text-gray-700">We trade mostly in</div>
										<p class="mt-1 max-w-2xl text-sm text-gray-500">Please choose your trade preferences</p>
									</div>
									<div>
										<div class="mt-2 sm:mt-0 max-w-lg space-y-4">
										<div class="flex items-center">
											<input
											id="used-equipment"
											name="trade-type"
											type="radio"
											value="used"
											class="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500"
											v-model="selectedTradeType"
											/>
											<label for="used-equipment" class="ml-3 block text-sm font-medium text-gray-700">Used equipment</label>
										</div>
										<div class="flex items-center">
											<input
											id="new-equipment"
											name="trade-type"
											type="radio"
											value="new"
											class="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500"
											v-model="selectedTradeType"
											/>
											<label for="new-equipment" class="ml-3 block text-sm font-medium text-gray-700">New equipment</label>
										</div>
										</div>
									</div>
									</div>
								</div>
								<div class='w-full clearfix mt-4 mb-8'>
									<button v-if='isEditMode' @click='updateCompanyDetails' type="button"
										class="btn btn-primary float-right mr-6">Update Company</button>
								</div>
							</div>
						</div>
						<div class="col-span-12 lg:col-span-6 bg-white shadow overflow-hidden sm:rounded-lg">

							<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
								<h3 class="text-lg leading-6 font-medium text-gray-900">Contact Details</h3>
							</div>

							<div class="grid grid-cols-12 gap-6 px-4 sm:px-6 mb-8 max-h-160 overflow-y-auto">
								<div v-for="(contact, index) in contactsEnriched" :key="index"
									class='col-span-12 sm:col-span-6 py-2 sm:gap-4 '>
									<div class='clearfix'>
										<div v-if='contact.profilePic'>
											<div v-if='contact.hasLargePic' @click='showLargePic(contact.id)'
												class="w-12 h-12 rounded-full float-left mr-2 cursor-pointer ring-4" alt="profile picture"
												:style="contact.picCSS"></div>
											<div v-else class="w-12 h-12 rounded-full float-left mr-2" alt="profile picture"
												:style="contact.picCSS">
											</div>
										</div>
										<div v-else class="w-12 h-12 rounded-full float-left mr-2 cursor-pointer ring-4">
											<div v-if='isEditMode && user.id === contact.id' @click="showProfilePicModal = true">
												<svg class="h-12 w-12" stroke="none" fill="none" viewBox="2 2 16 16">
													<path fill-rule="evenodd" clip-rule="evenodd"
														d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
														fill="gray" />
												</svg>
											</div>
											<div v-else>
												<svg class="h-12 w-12" stroke="none" fill="none" viewBox="2 2 16 16">
													<path fill-rule="evenodd" clip-rule="evenodd"
														d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
														fill="gray" />
												</svg>
											</div>
										</div>

										<div>
											<h4 class="mt-2 text-lg leading-6 font-medium text-gray-900 mb-0.5">{{ contact.name }}</h4>
											<div v-if='contact.label === "Main contact"' class="float-left tag  bg-red-100 text-red-800">
												{{ contact.label }}</div>
											<div v-else class="float-left tag bg-gray-100 text-gray-800">{{ contact.label }}</div>
											<a v-if='user && (!isEditMode || user.id !== contact.id)' href='javascript:void(0)'
												@click='$refs.contactModal.openSendMessageModal(contact)' class='inline-block ml-2'>
												<svg class="h-6 w-6 text-gray-400 float-left mr-2" fill="none" viewBox="0 0 24 24"
													stroke="currentColor">
													<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
														d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
												</svg>
											</a>
										</div>
									</div>


									<div v-if='!isEditMode || user.id !== contact.id'>

										<div v-if="contact.function" class="text-sm font-semibold text-gray-500">
											{{contact.function}}
										</div>
										<dl class="mt-2 text-sm leading-5 text-gray-500">
											<div class="mt-1" v-if='user && contact.tel'>
												<dt class="sr-only">Telephone</dt>
												<dl>
													<div class="float-left mr-2 mb-3">
														<a :href='"whatsapp://send?phone=" + formatWhatsAppNumber(contact.tel)'>
														<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
															viewBox="0 0 455.731 455.731" width="24" height="24" xml:space="preserve">
														<g>
															<rect x="0" y="0" style="fill:#1BD741;" width="455.731" height="455.731"/>
															<g>
															<path style="fill:#FFFFFF;" d="M68.494,387.41l22.323-79.284c-14.355-24.387-21.913-52.134-21.913-80.638
																c0-87.765,71.402-159.167,159.167-159.167s159.166,71.402,159.166,159.167c0,87.765-71.401,159.167-159.166,159.167
																c-27.347,0-54.125-7-77.814-20.292L68.494,387.41z M154.437,337.406l4.872,2.975c20.654,12.609,44.432,19.274,68.762,19.274
																c72.877,0,132.166-59.29,132.166-132.167S300.948,95.321,228.071,95.321S95.904,154.611,95.904,227.488
																c0,25.393,7.217,50.052,20.869,71.311l3.281,5.109l-12.855,45.658L154.437,337.406z"/>
															<path style="fill:#FFFFFF;" d="M183.359,153.407l-10.328-0.563c-3.244-0.177-6.426,0.907-8.878,3.037
																c-5.007,4.348-13.013,12.754-15.472,23.708c-3.667,16.333,2,36.333,16.667,56.333c14.667,20,42,52,90.333,65.667
																c15.575,4.404,27.827,1.435,37.28-4.612c7.487-4.789,12.648-12.476,14.508-21.166l1.649-7.702c0.524-2.448-0.719-4.932-2.993-5.98
																l-34.905-16.089c-2.266-1.044-4.953-0.384-6.477,1.591l-13.703,17.764c-1.035,1.342-2.807,1.874-4.407,1.312
																c-9.384-3.298-40.818-16.463-58.066-49.687c-0.748-1.441-0.562-3.19,0.499-4.419l13.096-15.15
																c1.338-1.547,1.676-3.722,0.872-5.602l-15.046-35.201C187.187,154.774,185.392,153.518,183.359,153.407z"/>
															</g>
														</g>
														</svg>
														</a>
													</div>
													<div class="mb-3">
														<a :href='contact.telLink' class='text-red-700'>{{ contact.tel }}</a>
													</div>
												</dl>
											</div>
											<div class="mt-1" v-if='user && contact.email && isEditMode'>
												<dt class="sr-only">Primary Email</dt>
												<dl>{{ contact.email }}</dl>
											</div>
										</dl>
										<div v-if="!isLoading.availCheck">
											<div v-if="timeslotRequest.id">
												<div v-if="!isOwnCompany() && enableSchedulerButtons">
													<button v-if="timeslotAvailable(contact.id, timeslotRequest.id)" type="button" class="button ml-0" @click="requestMeeting(contact, timeslotRequest)">Request Meeting on {{ timeslotRequest.day === 1 ? 'Thursday' : 'Friday' }} from {{timeslotRequest.timeStart}} - {{timeslotRequest.timeEnd}}</button>
													<button v-else type="button" class="button ml-0 bg-red-500 hover:bg-red-400" @click="requestMeeting(contact, {})">Not available in this timeslot<br />Click for more options</button>
												</div>
											</div>
											<div v-else>
												<button v-if="!isOwnCompany() && enableSchedulerButtons" type="button" class="button ml-0" @click="requestMeeting(contact, timeslotRequest)">Request Meeting</button>
											</div>
										</div>
									</div>
									<div v-else>
										<div class='mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6'>
											<div class='sm:col-span-6'>
												<label class="block text-sm font-medium leading-5 text-gray-700">Contact Name</label>
												<div class="mt-1 rounded-md shadow-sm">
													<input v-model="contact.name"
														class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
												</div>
											</div>
										</div>

										<div class='mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6'>
											<div class='sm:col-span-6'>
												<label class="block text-sm font-medium leading-5 text-gray-700">Contact Telephone</label>
												<div class="mt-1 rounded-md shadow-sm">
													<input v-model="contact.tel"
														class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
												</div>
											</div>
										</div>

										<div class='mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6'>
											<div class='sm:col-span-6'>
												<label class="block text-sm font-medium leading-5 text-gray-700">Contact Function</label>
												<div class="mt-1 rounded-md shadow-sm">
													<input v-model="contact.function"
														class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
												</div>
											</div>
										</div>

										<div class='mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6' v-if='isEditMode'>
											<div class='sm:col-span-6'>
												<label class="block text-sm font-medium leading-5 text-gray-700">Primary email</label>
												<div class="mt-1" v-if='user && contact.email'>
													<dl>{{ contact.email }}</dl>
												</div>
											</div>
										</div>

										<!-- edit knoppen -->
										<div class='w-full clearfix -mt-4 relative mb-10' v-if='isEditMode'>
											<div class='float-right mt-8'>
												<button @click='updateContactDetails' type="button" class="btn btn-primary">Update</button>
												<button type="button" class="ml-3 btn btn-muted"
													@click="showContactEditDropdown = !showContactEditDropdown">
													More
													<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
														<path fill-rule="evenodd"
															d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															clip-rule="evenodd" />
													</svg>
												</button>
											</div>
											<div v-if='showContactEditDropdown' v-on-clickaway="closeContactEditDropdown"
												class="origin-top-right absolute mt-20 right-0 w-56 rounded-md shadow-lg border">
												<div class="rounded-md bg-white shadow-xs">
													<div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
														<a href="javascript:void(0)" @click="doShowChangePasswordModal"
															class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
															role="menuitem">Change Password</a>
														<a href="javascript:void(0)" @click="showProfilePicModal = true"
															class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
															role="menuitem">Update Profile Picture</a>
														<a v-if='profilePic' href="javascript:void(0)" @click="showConfirmDeletePicture = true"
															class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
															role="menuitem">Remove Profile Picture</a>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>

					<modal v-show="showChangePasswordModal" @submit="submitChangePassword" @cancel="showChangePasswordModal = false"
						header="Reset Password" affirmativeButtonLabel='Reset Password' :disableSubmit='passwordSubmitDisabled'>
						<template v-slot:body>
							<div>
								<dl class='mt-4 mb-4 sm:-ml-6 sm:-mr-6'>
									<div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">New Password</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<password v-model="resetPasswordInput" @feedback="passwordFeedBack"
												defaultClass='form-input block w-full' :toggle='true' @score='passwordScoreFeedback' />
											<div class='text-blue-600' v-if='passwordSuggestion'>{{ passwordSuggestion }}</div>
										</dd>
									</div>
								</dl>
							</div>
						</template>
					</modal>

					<modal-contact ref='contactModal' action='sendMailToContact'></modal-contact>

					<modal v-show="showProfilePicModal" @submit="uploadPictureAndCloseModal" @cancel="showProfilePicModal = false"
						header="Change your profile picture" :disableSubmit="!hasImage" affirmativeButtonLabel='Upload picture'>
						<template v-slot:body>
							<div class='mt-8'>
								<image-uploader :debug="2" :maxWidth="400" :maxHeight="400" :quality="0.9" :preview=true
									:className="['fileinput', { 'fileinput--loaded': hasImage }]" accept=".jpg, .jpeg, .png"
									@input="setImage"></image-uploader>
							</div>
						</template>
					</modal>

					<modal v-show="showLargePicModal" @submit="showLargePicModal = false" @cancel="showLargePicModal = false"
						:doSkipHeader="true" :doSkipCancel="true" affirmativeButtonLabel='Close'>
						<template v-slot:body>
							<div class='mt-8'>
								<img v-if='profilePicLarge' :src='profilePicLarge' />
								<div v-else>No picture available</div>
							</div>
						</template>
					</modal>

					<modal v-show="showConfirmDeletePicture" @submit="removePictureAndCloseModal()"
						@cancel="showConfirmDeletePicture = false" header="Confirm delete" affirmativeButtonLabel='Yes, Delete'>
						<template v-slot:body>
							<div>Are you sure you want to delete this picture?</div>
						</template>
					</modal>

					<modal v-show="showMeetingRequestMessage" @submit="submitMeetingRequest()"
						@cancel="showMeetingRequestMessage = false" :header="`Requesting meeting with ${requestContactName}`" affirmativeButtonLabel='Request Meeting'>
						<template v-slot:body>
							<div class="mt-4">Anything you would like to add before sending this request?<br><br>
							<textarea v-model="meetingRequestMessage" class="form-input block w-full h-24 md:h-32"></textarea></div>
						</template>
					</modal>

					<modal v-show="showMeetingTimeslots" @submit="submitMeetingRequest()"
						@cancel="showMeetingTimeslots = false" :header="`Requesting meeting with ${requestContactName}`" :hideSubmit="true">
						<template v-slot:body>
							<div class="mt-4">These are the time slots in which both you and {{requestContactName}} are still available. Please select a time slot.</div>
				            <nav class="mb-2 flex space-x-8" aria-label="Tabs">
								<button :class="tabClass('0')" @click="currentTab = '0'">Thursday Oct 10</button>
								<button :class="tabClass('1')" @click="currentTab = '1'">Friday Oct 11</button>
							</nav>
							<div v-if="!isLoading.timeslots">
								<div v-for="timeslot in currentTimeslots" :key="timeslot.id" class="relative items-center space-x-3 rounded-lg shadow-sm focus-within:ring-2 focus-within:ring-red-500 focus-within:ring-offset-2 border" :class="{'timeslot-used': isTimeslotUsed(timeslot.id)}">
								<div>
									<div v-if="isTimeslotUsed(timeslot.id)" class="schedule-header text-white px-4 py-2 rounded-lg" :class="{'bg-gray-300': isTimeslotUsed(timeslot.id), 'bg-blue-500 hover:bg-blue-600': !isTimeslotUsed(timeslot.id)}">
										{{ timeslot.timeStart }} - {{ timeslot.timeEnd }}
										<div v-if="isTimeslotUsed(timeslot.id)" class="float-right"><em>Not available</em></div>
									</div>
									<div v-else class="schedule-header text-white px-4 py-2 rounded-lg cursor-pointer" :class="{'bg-gray-300': isTimeslotUsed(timeslot.id), 'bg-blue-500 hover:bg-blue-600': !isTimeslotUsed(timeslot.id)}" @click="requestMatchedMeeting(timeslot)">
										{{ timeslot.timeStart }} - {{ timeslot.timeEnd }}
										<div class="float-right">Request meeting</div>
									</div>
								</div>
								</div>
							</div>
						</template>
					</modal>
				</div>

			</div>
		</template>
	</base-layout>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _ from 'lodash';
import { mixin as clickaway } from 'vue-clickaway';
import ImageUploader from "vue-image-upload-resize"

import BaseLayout from '@/components/BaseLayout.vue'
import Modal from "@/components/modal"
import ModalContact from '@/components/ModalContact.vue'
import resetPassword from '@/mixins/resetPassword'

import store from '../store'
import eventBus from '../utils/eventBus'

export default {
	name: 'CompanyDetail',
	components: {
		BaseLayout,
		Modal,
		ModalContact,
		ImageUploader,
	},
	mixins: [clickaway, resetPassword],
	beforeRouteEnter(to, from, next) {
		let isProfile = to.name === 'profile'
		let companyId = to.params.id
		if (isProfile) {
			store.commit("bootstrapUserFromLocalStorage")
			if (!store.state.user) {
				next({ name: 'home' })
				return
			}
			companyId = store.state.user.companyId
		}
		store.dispatch('getCompany', companyId).then(resp => {
			if (resp.status !== 200) throw new Error("TODO: not found. show error page")
			next(vm => {
				vm.selectedCompany = resp.company;
				vm.contacts = resp.contacts;
				vm.isEditMode = isProfile;
				document.title = isProfile ? `Profile - European Broker Meeting` : `${vm.selectedCompany.name} - European Broker Meeting`
			})
		})
	},
	data() {
		return {
			contacts: [],
			selectedCompany: {},
			showBankInfo: false,
			isRecommendModalVisible: false,
			isRecommendationInvalid: true,
			recommendationText: "",

			showProfilePicModal: false,
			showConfirmDeletePicture: false,
			showMeetingRequestMessage: false,
			showMeetingTimeslots: false,
			requestContactName: null,
			picBase64: null,
			hasImage: false, //selected in image upload widget?

			showLargePicModal: false,
			profilePicLarge: null,

			isEditMode: false,
			enableSchedulerButtons: false, // in case we want to prevent scheduler actions from the attendee/company details page
			meetingRequest: [],
			meetingRequestMessage: null,
			takenTimeslotsAllContacts: {},
			takenTimeslotsMatched: {},
		    timeslotsByDay: null,
			isLoading: {
				availCheck: false,
				timeslots: true,
			},
			days: null,
			numTables: null,
			numTablesMax: null,
		    currentTab: "0", // 0 (day 1), 1 (day 2)
			usedTimeslotIds: null,
			selectedContact: null,
			maxCharacters: 350,
			payload: null,
			selectedCategories: [], // This will hold the selected category IDs
		    selectedTradeType: "",  // This will hold the selected trade type
		    errors: [],             // This will hold validation error messages

		}
	},
	watch: {
		selectedCompany(newVal) {
		if (newVal) {
			this.initializeForm();
		}
		},
		selectedCategories(newVal) {
			this.errors = [];

			// Enforce a minimum of 1 selection
			if (newVal.length === 0) {
				this.errors.push('Please select at least one option.');
			}

			// Enforce a maximum of 3 selections
			if (newVal.length > 3) {
				this.errors.push('You can select a maximum of 3 options.');
			}
		},	
	},
  	created() {
		this.initializeForm();
	},
	async mounted() {
		// if (this.user.companyId==7 || this.user.companyId==244) {this.enableSchedulerButtons = true} // added temporarily to enable scheduler buttons for testing, only for the test user with the IDs 23 and 481
		this.allSettings = await this.$http.get("/api/misc/allSettings").then(res => res.data.payload)
		this.enableSchedulerButtons = Boolean(Number(this.allSettings.schedulerEnabled))

		// we have all the contactIds so we get the taken timeslots for all users, which we can later use to check availability
		try {
			this.isLoading.availCheck = true
			const promises = this.contacts.map(contact => this.getAvailableTimeSlotIds(contact.id).then(timeslotIds => {
				return { contactId: contact.id, timeslotIds }
			}).catch(error => {
				console.error('Failed to fetch timeslot IDs for contact', contact.id, error)
				return { contactId: contact.id, timeslotIds: [] } // Assuming an error case returns empty array
			}));
			const results = await Promise.all(promises)
			results.forEach(result => {
				this.takenTimeslotsAllContacts[result.contactId] = result.timeslotIds
			})
			this.isLoading.availCheck = false
		} catch (error) {
			console.error('Error in processing timeslot data:', error);
		}
	},
	computed: {
		...mapState(["route", 'user', 'profilePic', 'timeslotRequest','categoryOptions']),
		categoryOptionsEnriched(){
			return _.map(this.categoryOptions, option => ({
				...option,
				vueKey: `option-${option.id}`
			}))
		},
		currentTimeslots() {
			return this.timeslotsByDay[+this.currentTab + 1]
		},
		selectedCompanyEnriched() {
			return this.selectedCompany;
		},
		contactsEnriched() {
			return this.contacts;
		},
		vatLabel() {
			return this.selectedCompanyEnriched.country === 'UK' ? "EORI" : "VAT"
		},
		remainingCharacters() {
			return this.maxCharacters - this.selectedCompanyEnriched.profile.length;
		},		
	},

	methods: {
		...mapActions(["updateCompany", "updateContact", "uploadPicture", 'removePicture']),
		openBadgeForDownload(badgeType) {
			window.open('/assets/' + badgeType + ".png")
		},
		async getAllTimeSlots() {
			let timeslots
			await this.$http.get("/api/scheduler/timeslots").then(res => {
				timeslots = res.data.timeslots
				this.days = [res.data.metaData.date_start.value, res.data.metaData.date_end.value] // always 2
				this.numTables = res.data.metaData.meeting_tables.value
				this.numTablesMax = res.data.metaData.meeting_tables_max.value // Including lobby seats
				this.timeslotsByDay = _.groupBy(timeslots, 'day')
			})
		},

		initializeForm() {
      const companyData = this.selectedCompanyEnriched;
      if (companyData && companyData.preferences) {
        const preferences = JSON.parse(companyData.preferences);

        // Set selected categories
        this.selectedCategories = preferences.categories || [];

        // Set selected trade type
        this.selectedTradeType = preferences.trading || "";
      }
    },

		async updateCompanyDetails() {
			// Check if remaining characters are below 0
			if (this.remainingCharacters < 0) {
				eventBus.$emit("createSnackbar", {
				type: "error",
				title: "Character limit exceeded",
				body: "Please reduce the number of characters in the Company Description field to proceed.",
				timeoutInMS: 5000,
				});
				return; // Exit the method without submitting
			}

			// Check if at least one category is chosen
			if (this.selectedCategories.length < 1) {
				eventBus.$emit("createSnackbar", {
				type: "error",
				title: "No main interest selected",
				body: "Please choose at least one main interest.",
				timeoutInMS: 5000,
				});
				return; // Exit the method without submitting
			}
			
			// process the preferences and trading types and update them in the selectedCompanyEnriched
			this.selectedCategories.sort((a, b) => a - b);
			const preferences = {
				categories: this.selectedCategories,
				trading: this.selectedTradeType
			};
			this.selectedCompanyEnriched.preferences = JSON.stringify(preferences)

			const selectedCompanyDTO = _.pick(this.selectedCompanyEnriched, ['id', 'tel', 'address', 'profile', 'website', 'preferences'])
			const resp = await this.updateCompany(selectedCompanyDTO);

			if (![200, 501, 502].includes(resp.data.status)) {
				eventBus.$emit("createSnackbar", {
					type: "error",
					title: "Something went wrong",
					body: `Company wasn't updated. Please try again. <br/> If the problem persists please contact European Broker Meeting (Code ${resp.data.status})`,
					timeoutInMS: 5000,
				})
			} else {
				eventBus.$emit("createSnackbar", {
					type: "success",
					title: "Company profile succesfully updated"
				})
			}
		},
		async updateContactDetails() {

			const contactDTO = _.pick(_.find(this.contactsEnriched, { id: this.user.id }), ['id', 'tel', 'function', 'name', 'imName', 'imValue', 'rfqDisabled', 'possibleMatchesDisabled']);

			const resp = await this.updateContact(contactDTO);

			if (resp.data.status !== 200) {
				eventBus.$emit("createSnackbar", {
					type: "error",
					title: "Something went wrong",
					body: "Contact wasn't updated. Please try again. <br/> If the problem persists please contact European Broker Meeting",
					timeoutInMS: 5000,
				})
			} else {
				eventBus.$emit("createSnackbar", {
					type: "success",
					title: "Contact succesfully updated",

				})
			}
		},
		closeContactEditDropdown() {
			this.showContactEditDropdown = false;
		},
		showBroadcastSettings() {
			this.$refs.BroadcastSettings.$emit("showModal")
			this.showContactEditDropdown = false;
		},
		checkRecommendationValid() {
			this.isRecommendationInvalid = this.recommendationText.length < 30
		},
		showRecommendModal() {
			this.isRecommendModalVisible = true;
			this.isRecommendationInvalid = false; //reset validation
		},
		closeRecommendModal() {
			this.isRecommendModalVisible = false;
		},
		setImage(picBase64) {
			this.hasImage = true
			this.picBase64 = picBase64
		},
		async removePictureAndCloseModal() {
			try {
				const resp = await this.removePicture()
				if (resp.data.status !== 200) throw resp
			} catch (err) {
				eventBus.$emit("createSnackbar", {
					type: "error",
					title: "Picture could not be deleted",
					body: "Please try again. If this occur keeps occuring, please contact European Broker Meeting",
					timeoutInMS: 5000,
				})
			}
			this.showConfirmDeletePicture = false
			this.$router.go(this.$router.currentRoute)
		},
		async uploadPictureAndCloseModal() {
			try {
				const resp = await this.uploadPicture({ picture: this.picBase64 })
				if (resp.data.status !== 200) throw resp

				this.closeUploadPictureModal()
				this.$router.go(this.$router.currentRoute)

			} catch (err) {
				eventBus.$emit("createSnackbar", {
					type: "error",
					title: "Profile Picture could not be uploaded",
					body: "Please try again. If this occur keeps occurring, please contact European Broker Meeting",
					timeoutInMS: 5000,
				})
			}

		},
		closeUploadPictureModal() {
			this.picBase64 = null;
			this.showProfilePicModal = false
		},
		async showLargePic(id) {
			const resp = await this.$http.get(`/api/companies/fetchLargePicture/${id}`)
			this.profilePicLarge = resp.data.profilePicLarge || null
			this.showLargePicModal = true;
		},
		openComplaintModal() {
			this.$parent.$parent.openComplaintModal()
		},

		isOwnCompany() {
			return this.user.companyId === this.selectedCompany.id
		},

		async requestMeeting(targetContact, timeslot) {
			this.requestContactName = targetContact.name
			this.selectedContact = targetContact

			if (timeslot.id) {
				this.meetingRequest.toId = targetContact.id
				this.meetingRequest.timeslotId = timeslot.id
				this.showMeetingRequestMessage = true
			} else {
				this.isLoading.timeslots = true
				try {
					const url = `/api/scheduler/contacts/${targetContact.id}/action/findCombinedTakenTimeslots`
					const resp = await this.$http.get(url)
					this.usedTimeslotIds = resp.data.usedTimeslotIds
					await this.getAllTimeSlots()
					this.showMeetingTimeslots = true
					this.isLoading.timeslots = false
				} catch(err) {
					console.error('Error during request cancellation:', err)
				}
			}
		},

		async requestMatchedMeeting(timeslot) {
			this.requestContactName = this.selectedContact.name
			this.meetingRequest.toId = this.selectedContact.id
			this.meetingRequest.timeslotId = timeslot.id
			this.showMeetingTimeslots = false
			this.showMeetingRequestMessage = true
		},

		async submitMeetingRequest() {
			const payload = {
				toId: this.meetingRequest.toId,
				timeslotId: this.meetingRequest.timeslotId,
				message: this.meetingRequestMessage,
			}
			this.showMeetingRequestMessage = false
			const resp = await this.$http.post("/api/scheduler/meetings", payload)
			console.log(resp.data)
			if (resp.data.status !== 200) {
				eventBus.$emit("createSnackbar", {
					type: "error",
					title: "There was a problem sending your meeting request",
					body: "Please try again. If this occur keeps occurring, please contact European Broker Meeting",
					timeoutInMS: 5000,
				})
			} else {
				eventBus.$emit("createSnackbar", {
					type: "success",
					title: "Meeting request was successfully sent",
				})

				// setTimeout(() => {
					this.$router.push(`/scheduler?cd=${resp.data.currentlySelectedDay}`);
					// }, 1000)
			}
		},
		timeslotAvailable( contactId, timeslotId ) {
			// console.log(`Checking timeslotId ${timeslotId} for contact ${contactId}`)
			// we have all the timeslots that are taken for each of the attendees. Now we check if the button should be displayed if the selected timeslot id is listed for a specific contact id
			const takenTimeslots = this.takenTimeslotsAllContacts[contactId]
			if (!takenTimeslots) {
				return true;  // Assuming true when there is no data
			}
			// Check if the timeslotId is not in the takenTimeslots array
			return !takenTimeslots.includes(timeslotId)
		},
		async getAvailableTimeSlotIds(contactId) {
			try {
				const url = `/api/scheduler/contacts/${contactId}/action/findTakenTimeslots`
				const response = await this.$http.get(url)
				return response.data.usedTimeslotIds // array with timeslots that are taken, use this to check if the current timeslot is in it
			} catch (error) {
				console.error('Error during request cancellation:', error)
			}
		},
		isTimeslotUsed(timeslotId) {
			return this.usedTimeslotIds.includes(timeslotId)
		},
		tabClass(index) {
			// console.log(this.currentTab)
			this.currentTab = String(this.currentTab) // Added by Brian: convert to string, because when using mobile, the value is passed as an integer and the === won't work, results in only displaying the cancelled meetings tab
			if (+index === +this.currentTab) return 'border-red-500 text-red-600 flex whitespace-nowrap border-b-2 py-4 px-1 font-medium'
			return 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1font-medium'
		},
		formatWhatsAppNumber(phoneString) {
			const firstPhoneString = phoneString.split(',')[0].trim();
			const cleanedString = firstPhoneString.replace(/[^\d]/g, '');
			return cleanedString;
		},

		updateRemainingCharacters() {
			this.remainingCharacters = this.maxCharacters - this.selectedCompanyEnriched.profile.length;
		},
		isCheckboxDisabled(id) {
			return this.selectedCategories.length >= 3 && !this.selectedCategories.includes(id);
		},
	},
}
</script>
<style scoped>
.member_normal {
	width: 40px;
	height: 40px;
	background: url("../assets/normal.png");
	background-size: cover;
}

.member_trusted {
	width: 40px;
	height: 40px;
	background: url("../assets/trusted.png");
	background-size: cover;
}

.member_gold {
	width: 40px;
	height: 40px;
	background: url("../assets/gold.png");
	background-size: cover;
}

.member_diamond {
	width: 40px;
	height: 40px;
	background: url("../assets/diamond.png");
	background-size: cover;
}
</style>
