<template>
  <div v-if='payload' :style="{ top: `${coordinates.y}px`, left: `${coordinates.x}px` }"
    class="fixed pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">

        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <div>
            <div>
              <div class="flex">
                <div v-if="payload.trustedInt" class="mr-1 flex-shrink-0">
                  <div class='float-left -mt-0.5 mr-2'>
                    <div v-if="payload.isDiamond" class='member_diamond'>
                    </div>
                    <div v-if="payload.isGold" class='member_gold'>
                    </div>
                    <div v-if="payload.isTrusted" class='member_trusted'>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="text-sm font-semibold text-gray-900">{{payload.name}}</p>
                </div>
              </div>
            </div>
            <div class='clear-both  mt-3'>
              <dl class="divide-y divide-gray-100">
                
                <div v-if="payload.trustedInt" class="py-2 sm:grid sm:grid-cols-3 gap-2 leading-6 ">
                  <dd class="text-sm font-medium text-gray-900 sm:col-span-3">{{payload.memberAsText}}</dd>
                </div>
                <div class="py-2 sm:grid sm:grid-cols-3 gap-2 leading-6 ">
                  <dt class="text-sm font-medium text-gray-900">Main interests</dt>
                  <dd class="text-sm text-gray-700 sm:col-span-2 sm:mt-0">{{payload.categories.join(", ")}}</dd>
                </div>
                <div class="py-2 sm:grid sm:grid-cols-3 gap-2 leading-6 ">
                  <dt class="text-sm font-medium text-gray-900">Trades mostly</dt>
                  <dd class="text-sm text-gray-700 sm:col-span-2 sm:mt-0">{{payload.tradingType}}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div class="ml-4 flex flex-shrink-0">

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Popover",
  data() {
    return {
      payload: null, 
      coordinates: { x: 0, y: 0 }
    }
  },
  methods: {
    changeData(target, payload) {
      this.payload = payload;
      let rect = target.getBoundingClientRect();
      this.coordinates = { x: rect.left + 20, y: rect.bottom };
    },
    close() {
      this.payload = null
    }
  },
}
</script>
<style scoped>
.member_trusted {
  width: 24px;
  height: 24px;
  background: url("../assets/trusted_small.png");
  background-size: cover;
}

.member_gold {
  width: 24px;
  height: 24px;
  background: url("../assets/gold_small.png");
  background-size: cover;
}

.member_diamond {
  width: 24px;
  height: 24px;
  background: url("../assets/member_diamond.png");
  background-size: cover;
}
</style>
