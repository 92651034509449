<template>
  <base-layout :do-skip-header="false">
    <template #main>
      <h1 class='text-3xl md:text-4xl font-bold mt-8'>
        Scan your lead
      </h1>

      <div class="block sm:hidden mt-8 w-auto bg-white shadow overflow-hidden sm:rounded-lg p-6 text-center">
        <QrScanner />
      </div>
      <div class="hidden sm:block mt-8">
          Please access this page through your mobile device in order to scan the QR codes of EBM attendees.
      </div>
      
    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";
import { mapState } from "vuex";
import QrScanner from '../components/QrScanner.vue';

export default {
  name: "Scan",
  components: {
    BaseLayout,
    QrScanner,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
