<template>
  <base-layout :do-skip-header="false">
    <template #main>
      <h1 class='text-3xl md:text-4xl font-bold mt-8'>
        Leads
      </h1>

      <div v-if="user">
        <div v-if="leads && leads.length">
          <div class="flex justify-center sm:flex sm:justify-end">
            <a :href="fileUrl" :download="fileName()" ref="downloadLink" class="hidden">Download</a>
            <button @click="downloadCSV" class="mt-4 md:mt-0 rounded-md border border-transparent bg-red-600 py-2 px-4 text-md sm:text-lg font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">
              Download Leads list
            </button>
            <button @click="newScan" class="block sm:hidden ml-2 mt-4 md:mt-0 rounded-md border border-transparent bg-red-600 py-2 px-4 text-md sm:text-lg font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">
              Add new Lead
            </button>
            <button @click.stop="confirmEmptyLeads()" class="ml-2 mt-4 md:mt-0 rounded-md border border-transparent bg-red-600 py-2 px-4 text-md sm:text-lg font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none shadow-lg float-right">Remove All</button>
          </div>
          <div class="mt-6 flex flex-col hidden lg:block">
            <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th class="table-cell-header pl-2">Name</th>
                      <th class="table-cell-header">Company name</th>
                      <th class="table-cell-header">Country</th>
                      <th class="table-cell-header">Email</th>
                      <th class="table-cell-header">Mobile</th>
                      <th class="table-cell-header">Date scanned</th>
                      <th class="table-cell-header"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, index) in leads">
                      <tr :key="item.id" class="bg-white transition duration-150 ease-in-out hover:bg-gray-100 cursor-pointer" @click="toggleExpanded(index)">
                        <td class="table-cell text-gray-900 pl-2 max-w-[12rem] truncate">
                          <span class='inline-block font-semibold truncate hover-highlight max-w-48 align-middle'>{{ item.contactName }}</span>
                        </td>
                        <td class="table-cell text-gray-500 max-w-[12rem] truncate">
                          <span class="truncate">{{ item.companyName }}</span>
                        </td>
                        <td class="table-cell text-gray-500 max-w-[8rem] truncate">{{ item.country }}</td>
                        <td class="table-cell text-gray-500 max-w-[12rem] truncate">
                          <span class="truncate">{{ item.email ? item.email : '-' }}</span>
                        </td>
                        <td class="table-cell text-gray-500 max-w-[8rem] truncate">
                          <span class="truncate">{{ item.mobile ? item.mobile : '-' }}</span>
                        </td>
                        <td class="table-cell text-gray-500 max-w-[12rem] truncate">
                          <span class="truncate">{{ formatDate(item.dateAdded) }}</span>
                        </td>
                        <td class="table-cell text-gray-500 w-24">
                          <button @click.stop="confirmDelLead(item.id, item.contactName)" class="mt-2 md:mt-0 rounded-md border border-transparent bg-red-600 py-2 px-4 text-md sm:text-md font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none shadow-lg float-right">Remove</button>
                        </td>
                      </tr>
                      <tr v-if="expandedIndex === index" :key="'expanded-' + item.id" class="bg-white">
                        <td colspan="7" class="p-4">
                          <div class="text-gray-500">
                            <p>Main Interests: {{ item.preferences }}</p>
                            <p>Trading mostly in {{ item.trading }} equipment</p>
                            <br>
                            <p v-if="item.notes"><strong>Your remarks:</strong><br> {{ item.notes }}</p>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-6 flex flex-col block lg:hidden">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div v-for="(item) in leads"  :key="item.id" class="align-middle inline-block w-auto shadow overflow-hidden sm:rounded-lg border-b border-gray-300 p-6 mt-6">
                  <strong>{{ item.contactName }}</strong><br>
                  {{ item.companyName }}, {{ item.country }}<br>
                  <span v-if="item.mobile">{{ item.mobile}}<br></span>
                  {{ item.email }}<br><br>
                  Trading mostly {{ item.trading }} equipment in {{ item.preferences }}<br>
                  Date scanned: {{ formatDate(item.dateAdded) }}<br><br>
                  <p v-if="item.notes"><strong>Your remarks:</strong><br> {{ item.notes }}</p>
                  <button @click="confirmDelLead(item.id, item.contactName)" class="mt-2 md:mt-0 rounded-md border border-transparent bg-red-600 py-2 px-4 text-md sm:text-md font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg float-right">Remove</button>
              </div>
            </div>
          </div>

          <div class="mt-8 hidden sm:block">To add Leads to this list, open this page on your mobile device to scan another attendee's QR code.<br>You can find their QR code on their badge or mobile device.</div>

        </div>
        <div v-else>
          <p class="mt-6">
            <strong>You currently have no leads listed.</strong><br>
            <span class="hidden sm:block">To add Leads to this list, open this page on your mobile device to scan another attendee's QR code.<br>You can find their QR code on their badge or mobile device.</span>
            <span class="block sm:hidden">To add Leads to this list, <a href="/scan" class="text-red-600">click here to scan</a> the QR code from another attendee.</span>
          </p>
        </div>      
      </div>

      <modal v-show="showConfirmDel" @submit="delLead()" @cancel="showConfirmDel = false" header="Confirm removal of Lead" affirmativeButtonLabel='Yes, confirm' cancelButtonLabel="No">
        <template v-slot:body>
          <div>Are you sure you want to delete <strong>{{ contactName }}</strong> from your Leads list?</div>
        </template>
      </modal>

      <modal v-show="showConfirmDelAll" @submit="emptyLeads()" @cancel="showConfirmDelAll = false" header="Confirm removal of ALL Leads" affirmativeButtonLabel='Yes, confirm' cancelButtonLabel="No">
        <template v-slot:body>
          <div><strong>WARNING:<br>This will remove all Leads and cannot be reversed.</strong><br><br>Are you sure you want to delete <strong>ALL Leads</strong> from your Leads list?<br><br></div>
        </template>
      </modal>

    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";
import { mapState } from "vuex";
import Modal from "@/components/modal"

export default {
  name: "Register",
  components: {
    BaseLayout,
    Modal,
  },
  data() {
    return {
      leads: [],
      expandedIndex: null,
      leadId: null,
      showConfirmDel: false,
      showConfirmDelAll: false,
      contactName: null,
      fileUrl: '',
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  async mounted() {
    await this.fetchLeads()
  },
  methods: {
    async fetchLeads() {
      try {
        const response = await this.$http.get(`/api/companies/fetchLeadsList/${this.user.id}`)
        this.leads = response.data.leads
      } catch (error) {
        console.error("Error:", error.message)
      }
    },
    toggleExpanded(index) {
      if (this.expandedIndex === index) {
        this.expandedIndex = null;
      } else {
        this.expandedIndex = index;
      }
    },    
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${day}/${month}/${year} at ${hours}:${minutes}`
    },
    confirmDelLead(id, contactName) {
      this.leadId = id
      this.showConfirmDel = true
      this.contactName = contactName
    },
    confirmEmptyLeads(id) {
      this.showConfirmDelAll = true
    },
    async delLead() {
      this.showConfirmDel = false
      try {
        const response = await this.$http.post(`/api/companies/removeLead/${this.leadId}`)
        this.expandedIndex = null
        await this.fetchLeads()
      } catch (error) {
        console.error("Error:", error.message)
      }
    },
    async emptyLeads() {
      this.showConfirmDelAll = false
      try {
        const response = await this.$http.post(`/api/companies/emptyLeads`)
        await this.fetchLeads()
      } catch (error) {
        console.error("Error:", error.message)
      }
    },
    async downloadCSV() {

      const response = await this.$http({
        url: '/api/misc/leadsAsCSV',
        method: 'GET',
        responseType: 'blob',
      })

      this.fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      this.$nextTick(() => this.$refs.downloadLink.click());
    },
    fileName() {
      return "leads-" + new Date().toISOString().slice(0, 10).replace(/-/g, '') + ".csv"
    },
    newScan() {
      location.href = "/scan"
    }
  }
};
</script>
