<template>
  <base-layout :do-skip-header="false">
    <template #main>
      <h1 class='text-3xl md:text-4xl font-bold mt-8'>Sponsors EBM Cyprus 2024</h1>

      <!-- <div class="py-6 text-xl font-bold text-red-700">
        This year we will have a maximum of 30 Sponsors/Exhibitors, please contact Mario@TheBrokerSite.com if you are interested in Sponsoring.
      </div> -->

        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          
          <h2 class='text-lg font-bold mt-8 mb-4'>Main Sponsor</h2>

          <div class="-mx-6 grid gap-4 o grid-cols-1 ">
            <a v-if='mainSponsor' href="javascript:void(0)" @click='toSponsorSite(mainSponsor)' class="bg-white shadow-xl shadow-indigo-900/5 pb-4">
              <!-- <div class="float-right pt-2 pr-2 uppercase text-xs text-gray-400">{{mainSponsor.sponsorType}}</div> -->
              <img class="max-h-32 pt-4 w-full object-contain" :src="mainSponsor.logoURL" :alt="mainSponsor.name"/>
            </a>
          </div>
          <h2 class='text-lg font-bold mt-8 mb-4'>Other Sponsors</h2>

          <div class="-mx-6 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <a href="javascript:void(0)" 
              @click='toSponsorSite(sponsor)' 
              v-for='sponsor in sponsors' 
              :key='sponsor.name' 
              class="bg-white shadow-xl shadow-indigo-900/5 pb-4"
              :class="{'sm:col-span-2': sponsor.sponsorType.includes('submain')}">
                <img class="max-h-32 pt-4 w-full object-contain" 
                :src="sponsor.logoURL" 
                :alt="sponsor.name"/>
            </a>
          </div>

        </div>

      <!-- <div class="py-8 text-xl font-bold text-red-700">
        This year we will have a maximum of 30 Sponsors/Exhibitors, please contact <a href="mailto:mario@thebrokersite.com?Subject=Sponsoring of the EBM 2024 in Cyprus" class="underline">Mario@TheBrokerSite.com</a> if you are interested in Sponsoring.
      </div> -->
      <!-- <h1 class="mt-10 text-3xl font-bold tracking-tight text-red-700">All 30 sponsorships for the EBM 2024 in Cyprus have been sold</h1> -->

    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";

export default {
  name: "Register",
  components: {
    BaseLayout,
  },
  data() {
    return {
      mainSponsor: null, 
      sponsors: [],
    };
  },
  async created(){
    const sponsors = await this.$http.get("/api/misc/sponsors").then(res => res.data.payload)
    const sponsorDTOs = _.map(sponsors, sponsor => {
      return _.extend({}, sponsor, {
        logoURL: `${window.location.origin}/images/sponsors/${sponsor.logo}`,
        sponsorType: `${sponsor.type} Sponsor`
      })
    })
    this.mainSponsor = _.find(sponsorDTOs, {type: "main"})
    this.sponsors = _.difference(sponsorDTOs, [this.mainSponsor])
  },
  methods: {
    toSponsorSite(sponsor){
      window.open(sponsor.url, "_blank");
    },
    isSubMainSponsor(sponsorType) {
      return sponsorType.includes('submain')
    },
  }
};
</script>

<style>
.special-sponsor img {
  grid-column: span 2;
}
</style>